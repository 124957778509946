import * as React from "react"

import Layout from "../../components/layout/layout"
import PluginsHeroSectionNew from "../../components/sections/plugins-hero-section-new"
import PluginsPageShopifyListSectionNew from "../../components/sections/plugins-page-shopify-list-section-new"
import Seo from "../../components/seo"


const PluginsShopifyPage = () => {
  return (
    <Layout>
      <Seo title="Best Shopify Apps" />

      <PluginsHeroSectionNew subTitle="Best Shopify Apps" />

      <PluginsPageShopifyListSectionNew />
    </Layout>
  )
}

export default PluginsShopifyPage
